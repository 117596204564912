.custom_disabled:disabled {
  background-color: aqua;
}

.table_header_color {
  color: #55d90f;
}
.ReactTable .rt-tbody {
  overflow: hidden;
}

.ReactTable .rt-tbody .rt-tr-group:hover {
  font-weight: 500;
  border-top: 1px solid #00e4e4;
  border-bottom: 1px solid #00e4e4;
  font-size: 1.1em;
  cursor: pointer;
}

.ReactTable .rt-tbody:nth-child(even) {
  background: #00e4e4;
}

.rt-tbody::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.rt-tbody {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.actions-center > .dropdown > button {
  background: none;
}

.actions-center > .dropdown > button:hover {
  background: #404040 !important;
}

.actions-center > .dropdown > button:active {
  background: #404040 !important;
}

.actions-center > .dropdown > button:visited {
  background: #404040 !important;
}

.actions-center > .dropdown > button:focus {
  background: #404040 !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 100%;
    margin: 1.75rem auto;
  }
}

.ReactTable .-pagination .-btn,
.ReactTable .-pagination .-btn:hover {
  background: rgb(50, 142, 182) !important;
}
