.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.btn-icon:active {
  border: 2px solid rgb(50, 142, 182) !important;
}
/* .btn-icon:hover {
  border: 2px solid red !important;
} */
