.react-select.react-select--is-disabled .react-select__control--is-disabled {
  background-color: rgb(25, 25, 26);
}
.react-select .react-select__control,
.react-select .react-select__control:hover {
  border: none;
  background-color: rgb(25, 25, 26);
}

.react-select.info
  .react-select__control
  .react-select__value-container--has-value
  .react-select__single-value,
.react-select.info
  .react-select__control
  .react-select__value-container--has-value
  .react-select__multi-value__label {
  color: white;
}
.react-select.react-select--is-disabled
  .react-select__multi-value--is-disabled.react-select__multi-value {
  background-color: none;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: rgb(25, 25, 26);
  color: white;
}
label {
  font-weight: 600;
  color: rgb(0, 228, 228);
}

.card label {
  color: rgb(0, 228, 228);
  margin-bottom: 10;
}

.form-group .form-control,
.input-group .form-control {
  background-color: rgb(25, 25, 26);
  border: none;
}
